import {
  Site,
  SyncConfigBaseLayer,
  SyncConfigView,
  SyncConfigPersonRole,
  SyncConfigSetting,
  SiteState,
  FeatureFlags,
} from './../services/site.service';
import { Component, OnInit } from '@angular/core';
import { SiteConfigService } from '../services/siteConfig.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SiteService } from '../services/site.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-sitedetails',
  templateUrl: './sitedetails.component.html',
  styleUrls: ['./sitedetails.component.css'],
})
export class SitedetailsComponent implements OnInit {
  constructor(
    private siteConfigService: SiteConfigService,
    private siteService: SiteService,
    private route: ActivatedRoute,
    private router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {}

  selectedTabGroupIndex = 0;
  tabGroupNames = ['details', 'layers', 'tagroles', 'sensors', 'tags', 'edgedevices', 'settings'];

  currentImagePath: string;

  baseLayers: SyncConfigBaseLayer[] = [];
  siteId: string;
  view: SyncConfigView;
  site: Site;
  roles: SyncConfigPersonRole[] = [];
  settings: SyncConfigSetting[] = [];
  vesselMapSrcUrl: SafeResourceUrl;
  siteStates = Object.keys(SiteState);
  siteStatesEnum = SiteState;

  featureFlags: FeatureFlags;
  siteRemoteUrl: string;

  ngOnInit() {
    this.siteId = this.route.snapshot.paramMap.get('siteId');
    this.getBaseLayers();
    this.getView();
    this.getRoles();
    this.getSite();
    this.getSettings();
  }

  getView() {
    this.siteService.getView(this.siteId).subscribe(
      (v) => {
        this.view = v;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getSite() {
    this.siteService.getSite(this.siteId).subscribe(
      (s) => {
        this.site = s;
        this.featureFlags = s.featureFlags ?? {
          connectPOB: true,
          gangway: false,
          connectFuel: false,
          cargoConditionMonitoring: false,
          cabinBunkManagement: false,
        };
        this.vesselMapSrcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://www.vesselfinder.com/aismap?zoom=undefined&lat=undefined&lon=undefined&width=100%&height=600&names=true&imo=' +
            this.site.imoNumber +
            '&track=true&fleet=false&fleet_name=false&fleet_hide_old_positions=false&clicktoact=false&store_pos=true&zoom=4'
        );
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getRoles() {
    this.siteService.getPersonRoles(this.siteId).subscribe(
      (roles) => {
        this.roles = roles;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getSettings() {
    this.siteService.getSettings(this.siteId).subscribe(
      (settings) => {
        this.settings = settings;
        const siteName = settings.find((s) => s.key === 'HostName')?.value;
        const env = environment.apiAddress.includes('staging')
          ? '.staging'
          : environment.apiAddress.includes('dev')
          ? '.dev'
          : '';
        this.siteRemoteUrl = `https://${siteName}.remote${env}.scanreach.com/`;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getBaseLayers() {
    this.siteService.getBaseLayers(this.siteId).subscribe(
      (layers) => {
        this.baseLayers = layers;
      },
      (err) => {
        console.error(err);
        this.snackBar.open('There was an error ' + err, null, { duration: 8000 });
      }
    );
  }

  onTabGroupChange(idx: number) {
    this.router.navigate(['../' + this.tabGroupNames[idx]], { relativeTo: this.route });
  }

  baseLayerFormChange(event: Event, layer: SyncConfigBaseLayer) {
    const target = event.target as any;
    const files = target.files;
    const fileCount: number = files.length;
    if (fileCount > 0) {
      layer.imageReference = files.item(0).lastModified + '-' + files.item(0).name;
    }
  }

  uploadImage(event: Event, layer: SyncConfigBaseLayer) {
    const target = event.target as any;
    const files = target.file.files;
    const fileCount: number = files.length;
    // layer.imageReference = Guid.newGuid();
    if (fileCount > 0) {
      // a file was selected
      const file = files.item(0);
      const formData = new FormData();
      formData.append('image', file);
      formData.append('name', layer.name);
      formData.append('imageReference', layer.imageReference);

      this.siteService.postBaseLayer(this.siteId, formData).subscribe(
        (s) => {
          this.getBaseLayers();
          this.snackBar.open('BaseLayer was uploaded', null, { duration: 8000 });
        },
        (err) => {
          this.snackBar.open('There was an error ' + err, null, { duration: 8000 });
        }
      );
    }
  }
  viewImage(layer: any) {
    this.siteConfigService.getLayerBlobSas(this.siteId, layer.imageReference).subscribe((s: any) => {
      this.currentImagePath = s.blobSas;
    });
  }
  deleteBaseLayer(layer: SyncConfigBaseLayer) {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
    confirmDialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.siteService.deleteBaseLayer(this.siteId, layer.id).subscribe(
          (s) => {
            this.getBaseLayers();
            this.snackBar.open('BaseLayer is deleted', null, { duration: 8000 });
          },
          (err) => {
            this.snackBar.open('There was an error ' + err, null, { duration: 8000 });
            console.error(err);
          }
        );
      }
    });
  }

  newBaseLayer() {
    this.baseLayers.push({
      id: null,
      lastUpdatedAt: null,
      deletedUtcDateTime: null,
      site: null,
      name: '',
      imageReference: '',
    });
  }

  newRole() {
    this.roles.push({
      id: null,
      lastUpdatedAt: null,
      deletedUtcDateTime: null,
      name: '',
    });
  }
  saveRoles() {
    forkJoin(
      this.roles.map((role) => {
        if (role.id == null) {
          // Role is new, post it
          return this.siteService.postPersonRole(this.siteId, role);
        } else {
          // Role is old
          return this.siteService.putPersonRole(this.siteId, role);
        }
      })
    ).subscribe(
      () => {
        this.snackBar.open('Roles is saved', null, { duration: 8000 });
        setTimeout(() => {
          this.getRoles();
        }, 200);
      },
      (error) => {
        console.error(error);
        this.snackBar.open('There was an error saving roles' + error.error, null, { duration: 8000 });
      }
    );
  }

  deleteRole(role: SyncConfigPersonRole) {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
    confirmDialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.siteService.deletePersonRole(this.siteId, role).subscribe(
          (s) => {
            this.getRoles();
            this.snackBar.open('Role deleted', null, { duration: 8000 });
          },
          (err) => {
            this.snackBar.open('There was an error deleting role ' + err, null, { duration: 8000 });
            console.error(err);
          }
        );
      }
    });
  }

  // ====================== Settings ============================
  newSetting() {
    this.settings.push({
      id: null,
      lastUpdatedAt: null,
      deletedUtcDateTime: null,
      key: '',
      value: '',
      description: '',
    });
  }
  saveSettings() {
    const requests = this.settings.map((setting) => {
      if (setting.id == null) {
        // Setting is new, post it
        return this.siteService.postSetting(this.siteId, setting);
      } else {
        // Setting is old
        return this.siteService.putSetting(this.siteId, setting);
      }
    });
    forkJoin(requests).subscribe(
      () => {
        this.snackBar.open('Settings are saved', null, { duration: 8000 });
        setTimeout(() => {
          this.getSettings();
        }, 200);
      },
      (err) => {
        console.error(err);
        this.snackBar.open('There was an error saving settings: ' + err.error, null, { duration: 6000 });
      }
    );
  }

  deleteSetting(setting: SyncConfigSetting) {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
    confirmDialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.siteService.deleteSetting(this.siteId, setting).subscribe(
          (s) => {
            this.getSettings();
            this.snackBar.open('Setting deleted', null, { duration: 8000 });
          },
          (err) => {
            this.snackBar.open('There was an error deleting setting ' + err, null, { duration: 8000 });
            console.error(err);
          }
        );
      }
    });
  }

  saveView() {
    this.view.name = this.site.name;
    this.siteService.putView(this.siteId, this.view).subscribe(
      (s) => {
        this.getView();
        this.snackBar.open('View is saved', null, { duration: 8000 });
      },
      (err) => {
        this.snackBar.open('There was an error saving view ' + err, null, { duration: 8000 });
        console.error(err);
      }
    );
  }

  saveSite() {
    this.site.featureFlags = this.featureFlags;
    this.siteService.putSite(this.siteId, this.site).subscribe(
      (response) => {
        this.getSite();
        this.snackBar.open(response, null, { duration: 10000, panelClass: ['snackbar'] });
      },
      (err) => {
        this.snackBar.open(err.error, null, { duration: 10000, panelClass: ['snackbar'] });
        console.error(err);
      }
    );
  }
}
