<h1>Sites</h1>
<p>
  Changing releaseTag for sites leads to setting 'tags.release' in Azure IotHub for all edgeDevices connected to these
  sites. Azure IotHub will then send the new releaseTag to the edgeDevices, and the gateways will try to download
  deployment manifest which targets this releaseTag.
</p>
<p>
  The toggle filters filters sites on the state property. This property should reflect the delivery state of the site.
  Such that pending means planned to be installed. And prod means that system is installed and in use.
</p>
<button mat-raised-button color="primary" [disabled]="selected.selected.length == 0" (click)="openDialog()">
  Set releaseTag for {{ selected.selected.length }} selected sites
</button>
<br />
<br />
<div class="search-form">
  <mat-form-field appearance="fill" style="width: 250px">
    <mat-label>Search</mat-label>
    <input
      matInput
      type="string"
      placeholder="Search for name, customer, state, releaseTag"
      [(ngModel)]="filters.searchString"
      (input)="filterSites()"
    />
  </mat-form-field>
  <mat-slide-toggle [(ngModel)]="filters.prod" (change)="filterSites()"> Prod </mat-slide-toggle>
  <mat-slide-toggle [(ngModel)]="filters.pending" (change)="filterSites()"> Pending </mat-slide-toggle>
  <mat-slide-toggle [(ngModel)]="filters.dev" (change)="filterSites()"> Dev </mat-slide-toggle>
  <mat-slide-toggle [(ngModel)]="filters.discontinued" (change)="filterSites()"> Discontinued </mat-slide-toggle>
  <mat-slide-toggle [(ngModel)]="filters.demo" (change)="filterSites()"> Demo </mat-slide-toggle>
</div>
<br />
<p>Showing {{ sitesTableSource.data.length }} of {{ sites ? sites.length : 'null' }} sites:</p>
<div
  *ngIf="sites; let sites; else: loading"
  style="max-height: 75vh; overflow-y: scroll; width: 100%; max-width: fit-content"
>
  <table
    mat-table
    [dataSource]="sitesTableSource"
    matSort
    class="mat-elevation-z8"
    style="width: 100%"
    aria-label="Filtered table of all sites"
  >
    <ng-container matColumnDef="select">
      <th *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selected.hasValue() && isAllSelected()"
          [indeterminate]="selected.hasValue() && !isAllSelected()"
          class="w-64"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element" class="w-64">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selected.toggle(element) : null"
          [checked]="selected.isSelected(element)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let element">
        <a
          mat-stroked-button
          raised
          [routerLink]="['/customer/' + element.customer.id + '/site/' + element.id + '/details']"
          >{{ element.id }}</a
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
      <td mat-cell *matCellDef="let element">{{ element.customer.name }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
      <td mat-cell *matCellDef="let element">{{ element.state }}</td>
    </ng-container>
    <ng-container matColumnDef="releaseTag">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ReleaseTag</th>
      <td mat-cell *matCellDef="let element">{{ element.releaseTag }}</td>
    </ng-container>
    <ng-container matColumnDef="connectPOB">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ConnectPOB</th>
      <td mat-cell *matCellDef="let element">{{ element.featureFlags?.connectPOB }}</td>
    </ng-container>
    <ng-container matColumnDef="gangway">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Gangway</th>
      <td mat-cell *matCellDef="let element">{{ element.featureFlags?.gangway }}</td>
    </ng-container>
    <ng-container matColumnDef="connectFuel">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ConnectFuel</th>
      <td mat-cell *matCellDef="let element">{{ element.featureFlags?.connectFuel }}</td>
    </ng-container>
    <ng-container matColumnDef="cargoConditionMonitoring">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CargoConditionMonitoring</th>
      <td mat-cell *matCellDef="let element">{{ element.featureFlags?.cargoConditionMonitoring }}</td>
    </ng-container>
    <ng-container matColumnDef="cabinBunkManagement">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CabinBunkManagement</th>
      <td mat-cell *matCellDef="let element">{{ element.featureFlags?.cabinBunkManagement }}</td>
    </ng-container>
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Comment</th>
      <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <!-- TODO: Link to details page for Site -->
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<ng-template #nullTemplate>Null</ng-template>

<ng-template #loading>
  <div class="loading-indicator">
    <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
  </div>
</ng-template>
