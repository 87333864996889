import { HardwareDevice } from './hw.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Customer } from './customer.service';
@Injectable()
export class SiteService {
  constructor(private httpClient: HttpClient) {}

  private readonly apiAddress = environment.apiAddress + '/site/';

  // ====================== Blob, used to get image ============================
  getblobsas(siteConfigId: string, imageReference: string): Observable<Record<string, any>> {
    return this.httpClient.get(`${this.apiAddress}${siteConfigId}/layer/${imageReference}/getblobsas`);
  }

  // ====================== Site ============================
  getSite(siteId: string): Observable<Site> {
    return this.httpClient.get<Site>(this.apiAddress + siteId);
  }

  getSites(): Observable<Site[]> {
    return this.httpClient.get<Site[]>(this.apiAddress);
  }

  getValidDeploymentTargetConditions(): Observable<string[]> {
    return this.httpClient.get<string[]>(this.apiAddress + 'deployment-target-conditions');
  }

  deleteSite(siteId: string): Observable<string> {
    return this.httpClient.delete(this.apiAddress + siteId, { responseType: 'text' });
  }

  putSite(siteId: string, site: Site): Observable<string> {
    return this.httpClient.put(this.apiAddress + siteId, site, { responseType: 'text' });
  }

  // ====================== Add/remove hardware ============================
  connectSensorsByMac(siteId: string, macAddresses: string[]): Observable<string> {
    return this.httpClient.post(this.apiAddress + siteId + '/connectSensorsByMac', macAddresses, {
      responseType: 'text',
    });
  }
  connectTagsByMac(siteId: string, macAddresses: string[]): Observable<string> {
    return this.httpClient.post(this.apiAddress + siteId + '/connectTagsByMac', macAddresses, {
      responseType: 'text',
    });
  }
  disconnectHardwareDevices(siteId: string, hardwareToDisconnect: BulkAddHardwareToSite): Observable<string> {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: hardwareToDisconnect,
      responseType: 'text' as 'json',
    };
    return this.httpClient.request<string>('DELETE', this.apiAddress + siteId + '/siteconfig/hardware/', options);
  }

  // ====================== Nodes ============================
  getNodes(siteId: string): Observable<SyncConfigNode[]> {
    return this.httpClient.get<SyncConfigNode[]>(this.apiAddress + siteId + '/siteconfig/node');
  }

  // ====================== Tags ============================
  getTags(siteId: string): Observable<SyncConfigWearable[]> {
    return this.httpClient.get<SyncConfigWearable[]>(this.apiAddress + siteId + '/siteconfig/tag');
  }

  // ====================== EdgeDevices ============================
  getEdgeDevices(siteId: string): Observable<EdgeDevice[]> {
    return this.httpClient.get<EdgeDevice[]>(this.apiAddress + siteId + '/siteconfig/edgeDevice');
  }

  // ====================== View ============================
  getView(siteId: string): Observable<SyncConfigView> {
    return this.httpClient.get<SyncConfigView>(this.apiAddress + siteId + '/siteconfig/view');
  }

  putView(siteId: string, view: SyncConfigView): Observable<string> {
    return this.httpClient.put(this.apiAddress + siteId + '/siteconfig/view/' + view.id, view, {
      responseType: 'text',
    });
  }

  // ====================== BaseLayers ============================
  getBaseLayers(siteId: string): Observable<SyncConfigBaseLayer[]> {
    return this.httpClient.get<SyncConfigBaseLayer[]>(this.apiAddress + siteId + '/siteconfig/layer');
  }
  postBaseLayer(siteId: string, formData: FormData): Observable<string> {
    return this.httpClient.post(this.apiAddress + siteId + '/siteconfig/layer', formData, {
      responseType: 'text',
    });
  }

  deleteBaseLayer(siteId: string, baseLayerId: string): Observable<string> {
    return this.httpClient.delete<string>(this.apiAddress + siteId + '/siteconfig/layer/' + baseLayerId, {
      responseType: 'text' as 'json',
    });
  }

  // ====================== PersonRoles ============================
  getPersonRoles(siteId: string): Observable<SyncConfigPersonRole[]> {
    return this.httpClient.get<SyncConfigPersonRole[]>(this.apiAddress + siteId + '/siteconfig/personRole');
  }
  postPersonRole(siteId: string, role: SyncConfigPersonRole): Observable<string> {
    return this.httpClient.post(this.apiAddress + siteId + '/siteconfig/personRole', role, {
      responseType: 'text',
    });
  }
  putPersonRole(siteId: string, role: SyncConfigPersonRole): Observable<string> {
    return this.httpClient.put(this.apiAddress + siteId + '/siteconfig/personRole/' + role.id, role, {
      responseType: 'text',
    });
  }
  deletePersonRole(siteId: string, role: SyncConfigPersonRole): Observable<string> {
    return this.httpClient.delete(this.apiAddress + siteId + '/siteconfig/personRole/' + role.id, {
      responseType: 'text',
    });
  }

  // ====================== Settings ============================
  getSettings(siteId: string): Observable<SyncConfigSetting[]> {
    return this.httpClient.get<SyncConfigSetting[]>(this.apiAddress + siteId + '/siteconfig/setting');
  }
  postSetting(siteId: string, setting: SyncConfigSetting): Observable<string> {
    return this.httpClient.post(this.apiAddress + siteId + '/siteconfig/setting', setting, {
      responseType: 'text',
    });
  }
  putSetting(siteId: string, setting: SyncConfigSetting): Observable<string> {
    return this.httpClient.put(this.apiAddress + siteId + '/siteconfig/setting/' + setting.id, setting, {
      responseType: 'text',
    });
  }
  deleteSetting(siteId: string, setting: SyncConfigSetting): Observable<string> {
    return this.httpClient.delete(this.apiAddress + siteId + '/siteconfig/setting/' + setting.id, {
      responseType: 'text',
    });
  }

  connectHardwareDevices(
    siteId: string,
    hardwareToAdd: BulkAddHardwareToSite,
    addMacIfNotExists?: boolean,
    moveHardwareIfAlreadyConnected?: boolean
  ): Observable<string> {
    let params = new HttpParams();
    params = params.set('addMacIfNotExists', addMacIfNotExists ?? false);
    params = params.set('moveHardwareIfAlreadyConnected', moveHardwareIfAlreadyConnected ?? false);
    return this.httpClient.post(this.apiAddress + siteId + '/siteconfig/hardware/', hardwareToAdd, {
      params,
      responseType: 'text',
    });
  }
}

export interface Site {
  id: string;
  name?: string;
  imageUrl?: string;
  imoNumber?: string;
  mmsi?: string;
  state: SiteState;
  releaseTag: string;
  customer: Customer;
  comment: string;
  featureFlags: FeatureFlags;
}

export enum SiteState {
  production = 'production',
  pending = 'pending',
  development = 'development',
  demo = 'demo',
  discontinued = 'discontinued',
}

export interface FeatureFlags {
  connectPOB: boolean;
  gangway: boolean;
  connectFuel: boolean;
  cargoConditionMonitoring: boolean;
  cabinBunkManagement: boolean;
}

export interface EdgeDevice extends HardwareDevice {
  azureIotHubHostName: string;
  azureIotEdgeDeviceId: string;
  authTenantId: string;
  authApplicationId: string;
  iotEdgeTags: object;
  vpnName: string;
  vpnPort: string;
}

export interface SyncConfigBaseClass {
  id: string;
  lastUpdatedAt: Date;
  deletedUtcDateTime: Date;
}

export interface SyncConfigView extends SyncConfigBaseClass {
  name: string;
  width: number;
  height: number;
}

export interface SyncConfigBaseLayer extends SyncConfigBaseClass {
  name: string;
  imageReference: string;
  site: Site;
}

export interface SyncConfigNode extends SyncConfigBaseClass {
  label: string;
  x: number;
  y: number;
  musterStation: SyncConfigMusterStation;
  hardwareDevice: HardwareDevice;
}

export interface SyncConfigWearable extends SyncConfigBaseClass {
  hardwareDevice: HardwareDevice;
}

export interface SyncConfigPersonRole extends SyncConfigBaseClass {
  id: string;
  name: string;
}

export interface SyncConfigSetting extends SyncConfigBaseClass {
  id: string;
  key: string;
  value: string;
  description: string;
}

export interface SyncConfigMusterStation extends SyncConfigBaseClass {
  label: string;
  nodes: SyncConfigNode[];
  site: Site;
}

/**
 * If safeMode is true => no devices which is connected to anotehr site will be moved
 */
export interface BulkAddHardwareToSite {
  safeMode: boolean;
  hardwareIds?: string[];
  hardwareMacs?: string[];
  hardwareType?: string;
}

// ==========================================
// ==========================================
